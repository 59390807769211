<template>
  <div class="course-play-page">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span
          @click="
            $router.push(
              `/courlist?tid=${$route.query.tid}&tn=${$route.query.tn}`
            )
          "
          >{{ courseName }}</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">{{ videoInfo.title }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="player-box">
      <div class="player-video" id="dplayer">
        <VideoPlayer
          v-if="JSON.stringify(videoInfo) !== '{}'"
          :videoInfo="videoInfo"
          ref="videoEle"
        />
      </div>
      <div class="player-chapter">
        <span>课程目录</span>
        <ul>
          <li
            v-for="video in videoList"
            :key="video.id"
            @click="changeVideo(video.id)"
          >
            <span class="course-title"> {{ video.video_num }} </span>
            <span class="course-name"> {{ video.title }} </span>
            <!-- <span class="course-time"
              >（{{ formatSeconds(video.video_time) }}）</span
            > -->
            <!-- MARK:-20%完成 -->
            <!-- MARK:50%未完成 -->
            <i
              v-if="videoInfo.click_video_id != video.id"
              class="course-status"
              :style="{
                backgroundPositionY:
                  video.finish_see === 1
                    ? '-20%'
                    : video.finish_see === 0
                    ? '120%'
                    : '50%',
              }"
            ></i>
            <i
              class="course-playing"
              v-if="videoInfo.click_video_id == video.id"
            >
              <img src="../assets/images/playing.gif" alt="" />
            </i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  data() {
    return {
      videoList: [],
      videoInfo: {},
      courseName: "档案课程",
    };
  },
  components: { VideoPlayer },
  mounted() {
    this.getVideo();
  },
  methods: {
    getVideo() {
      const { vid, cid, ct } = this.$route.query;
      this.courseName = ct;
      let fd = new FormData();
      fd.append("course_id", cid);
      fd.append("video_id", vid);
      this.axios.post("/index/video_play", fd).then((res) => {
        this.videoList = res.data.video_info;
        this.videoInfo = res.data.data;
        // this.videoOptions.video.url = res.data.data.video_url;
      });
    },
    // 秒转时分秒
    formatSeconds(time) {
      let result = parseInt(time);
      let h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      if (h !== "00") res += `${h}:`;
      if (m !== "00") res += `${m}:`;
      res += `${s}`;
      return res;
    },
    changeVideo(vid) {
      this.$router.push(
        `/courplay?vid=${vid}&cid=${this.$route.query.cid}&ct=${this.$route.query.ct}&tid=${this.$route.query.tid}&tn=${this.$route.query.tn}`
      );
      this.$refs.videoEle.updateTime();
      this.$refs.videoEle.addStudyTime();
      this.$router.go(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.course-play-page {
  min-height: calc(100vh - 80px);
  background-color: #f8f8f8;
  overflow: hidden;
  .player-box {
    max-width: 1200px;
    margin: 0 auto;
    .player-video {
      float: left;
      width: 872px;
      height: 540px;
    }
    .player-chapter {
      float: right;
      width: 306px;
      height: 540px;
      background: #ffffff;
      border-radius: 8px;
      padding: 17px 0;
      box-sizing: border-box;
      & > span {
        font-size: 18px;
        color: #333;
        position: relative;
        margin-left: 25px;
        &::before {
          content: "";
          display: block;
          width: 2px;
          height: 18px;
          margin-left: 17px;
          background: #2badef;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      & > ul {
        padding: 0;
        li {
          list-style-type: none;
          width: 100%;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          font-size: 14px;
          color: #333;
          padding-left: 17px;
          padding-right: 13px;
          box-sizing: border-box;
          &:hover {
            background-color: #fafafa;
          }
          .course-title {
            display: inline-block;
            overflow: hidden;
          }
          .course-name {
            display: inline-block;
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 10px;
          }
          .course-status {
            display: block;
            float: right;
            width: 20px;
            height: 100%;
            background-image: url(../assets/images/course_status.png);
            background-repeat: no-repeat;
            background-position-y: 50%;
          }
          .course-playing {
            display: block;
            width: 20px;
            height: 100%;
            float: right;
            img {
              width: 100%;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>
